import React from 'react'
import TabWithDropdown from '../../layout/TabWithDropdown'
import DeliveryDetailsTab from './DeliveryDetailsTab'

export default class ShowDeliveryView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessfulMessage: false,
      alertState: 'alert alert-success',
      createText: 'Guardar',
      isPosting: false,
      activity: props.defaultDetails
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ details: nextProps.defaultDetails })
  }

  componentDidMount() {
    this.showSuccessfulMessageCreation()
  }

  showSuccessfulMessageCreation = () => {
    if (document.referrer.includes('nuevo_pedido')) {
      this.setState({ showSuccessfulMessage: true })
    } else {
      this.setState({ showSuccessfulMessage: false })
    }
  }

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  }

  disableButton = () => {
    this.setState({ createText: 'Guardando...', isPosting: true })
  }

  enableButton = () => {
    this.setState({ createText: 'Guardar', isPosting: false })
  }

  render() {
    return (
      <div>
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="main-show-header">
            <h2>Entrega: {this.state.activity?.uid}</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div>
                <div className="status-orders">
                  <b className="order-status-b">Estado:</b>
                  {this.state.activity?.delivery_type === 'delivery_receipt' && (
                    <span className="order-status delivery-received-style"> ENTREGADO</span>
                  )}
                  {this.state.activity?.delivery_type === 'partial_delivery_receipt' && (
                    <span className="order-status delivery-received-style"> PARCIAL</span>
                  )}
                  {this.state.activity?.status === 'open' && (
                    <span className="order-status delivery-open-style"> POR ENTREGAR</span>
                  )}
                </div>
                <div>
                  <div>
                    {(this.state.activity?.status === 'received' ||
                      this.state.activity?.status === 'sent' ||
                      this.state.activity?.status === 'open') && (
                      <ul className="nav nav-tabs">
                        <TabWithDropdown name="Entrega" activeTab={'Entrega'} items={[]} />
                      </ul>
                    )}
                  </div>

                  <div>
                    {this.state.activity && (
                      <DeliveryDetailsTab
                        activity={this.state.activity}
                        transactionUuid={this.props.details.uuid}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
