import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import {
  capitalizeFirstLetterFrom,
  effectiveLocationWithAllowedLocation,
  formatMoney
} from '../../helpers/utils'
import { v5 as uuidv5 } from 'uuid'
import { Table } from 'react-bootstrap'
import moment from 'moment'

const DeliveryDetailsTab = ({ activity: initialActivity = [], transactionUuid }) => {
  const [activity, setActivity] = useState(initialActivity)
  const [taxSettings] = useState(cookie.load('tax_settings'))

  useEffect(() => {
    setActivity(initialActivity)
  }, [initialActivity])

  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  const calculateSubTotal = () => {
    return (
      activity.details?.reduce((total, obj) => {
        const calculatedTaxPrice = obj.calculated_tax_price || obj.extended_price
        return total + parseFloat(calculatedTaxPrice)
      }, 0) || 0
    )
  }

  return (
    <div className="container-fluid">
      {(transactionUuid || activity.uid) && (
        <>
          <div className="row show-product-header">
            <div className="col-sm-6">
              <h3 className="margin-text-subtitle">Detalles de entrega:</h3>
            </div>
          </div>
          <hr />
        </>
      )}
      <div className="show-product-header-ubiqua-app show-header">
        <div>
          <div className="order-client-name">{activity.client_name}</div>
          <div className="order-client-db_ref">Código: {activity.client_db_ref}</div>
        </div>
        {/* {activity.delivery_type === 'delivery_receipt' && (
          <button className="float-right download-delivery-button" onClick={() => {}}>
            Descargar entrega
            <FontAwesomeIcon icon={faArrowCircleDown} />
          </button>
        )} */}
      </div>
      <hr />
      <table cellSpacing="10">
        <tbody>
          {[
            {
              label: 'Fecha de documento:',
              value: activity.collected_at
                ? getFormattedDate(activity.collected_at)
                : activity.created_at
                  ? getFormattedDate(activity.created_at)
                  : '-'
            },
            {
              label: 'Fecha de asignación:',
              value: activity.visit_date ? moment(activity.visit_date).format('DD/MM/YYYY') : '-'
            },
            {
              label: 'Fecha de ejecución:',
              value: activity.created_date
                ? getFormattedDate(activity.created_date)
                : activity.collected_at
                  ? getFormattedDate(activity.collected_at)
                  : '-'
            },
            {
              label: 'Número de entrega:',
              value: activity.status === 'open' ? '-' : activity.uid || '-'
            },
            {
              label: 'Vendedor:',
              value:
                activity.salesman_db_ref && activity.salesman_name
                  ? `${activity.salesman_db_ref} - ${activity.salesman_name}`
                  : '-'
            },
            {
              label: 'Tipo de cliente:',
              value:
                activity.status === 'open'
                  ? activity.delivery_type === 'E'
                    ? 'Factura'
                    : activity.delivery_type === 'C'
                      ? 'Crédito'
                      : '-'
                  : activity.client_payment_type
                    ? capitalizeFirstLetterFrom(activity.client_payment_type)
                    : '-'
            },
            {
              label: 'Número de factura:',
              value: activity.delivery_reference || '-'
            },
            {
              label: 'Referencia del cobro:',
              value: activity.payment_reference ? (
                <a
                  className="ubiqua-link"
                  target="_blank"
                  href={`/cobros/${uuidv5(
                    `${cookie.load('business_tenant_uid')}~${activity.payment_reference}`,
                    'd90e460b-8136-48a0-8d50-760d2fec9a39'
                  )}`}
                >
                  {activity.payment_reference}
                </a>
              ) : (
                '-'
              )
            },
            {
              label: 'Dentro de geocerca:',
              value:
                effectiveLocationWithAllowedLocation(
                  activity.effective_location,
                  activity.has_allowed_location
                ) || '-'
            }
          ].map(({ label, value }, idx) => (
            <tr key={idx}>
              <td>
                <div className="mr-85 mb-10">
                  <b>{label}</b>
                </div>
              </td>
              <td>
                <div className="mb-10">{value}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <div className="show-data-details">
        <div>
          <b>Productos:</b>
        </div>
      </div>
      <Table hover responsive>
        <thead className="table-header-gray">
          <tr>
            <th>CÓDIGO</th>
            <th>NOMBRE DEL PRODUCTO</th>
            <th>UNIDAD DE VENTA</th>
            <th>CANTIDAD</th>
            <th>PRECIO</th>
            <th className="text-center">SUB-TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {activity.details?.map((model, index) => (
            <tr key={index}>
              <td>{model.product_db_ref}</td>
              <td>{model.product_name}</td>
              <td>{model.unit}</td>
              <td>{parseInt(model.quantity)}</td>
              <td>{formatMoney(Number(model.price))}</td>
              <td className="text-center">{formatMoney(Number(model.extended_price))}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="totals-container-delivery">
        <div className={`totals-card ${!activity.signature_name ? 'hidden-content' : ''}`}>
          <div className="totals-line">
            <div>
              <b>Recibido por:</b>
            </div>
            <div>{activity.signature_name}</div>
          </div>
          {activity.signature_b64 && (
            <img width={400} src={`data:image/bmp;base64,${activity.signature_b64}`} alt="Firma" />
          )}
        </div>
        <div className="totals-card">
          <div className="totals-line">
            <div>
              <b>SUB-TOTAL:</b>
            </div>
            <div>{formatMoney(activity.subtotal || calculateSubTotal())}</div>
          </div>
          {taxSettings.tax_rate_2 && (
            <div className="totals-line">
              <div>
                <b>{taxSettings.tax_rate_2}:</b>
              </div>
              <div>{formatMoney(activity.taxes_2 || 0)}</div>
            </div>
          )}
          <div className="totals-line">
            <div>
              <b>{taxSettings.tax_rate}:</b>
            </div>
            <div>{formatMoney(activity.taxes || 0)}</div>
          </div>
          <div className="totals-line">
            <div>
              <h4>
                <b>TOTAL:</b>
              </h4>
            </div>
            <div>{formatMoney(activity.total)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeliveryDetailsTab
