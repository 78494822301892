import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import {
  capitalizeFirstLetterFrom,
  effectiveLocationWithAllowedLocation,
  formatMoney
} from '../../helpers/utils'
import { Table } from 'react-bootstrap'

const RefundDetailsTab = ({ activity = {}, transactionUuid }) => {
  const [taxSettings, setTaxSettings] = useState(cookie.load('tax_settings'))

  const getFormattedDate = (date) => {
    return date ? new Date(date).toLocaleString() : '-'
  }

  const getWeight = (weight = 0) => {
    const formattedWeight = Number(weight).toFixed(2)
    return formattedWeight === '0.00' ? 'N/A' : formattedWeight
  }

  const getRowSubtotal = (extendedPrice = 0, discountedPrice = 0) => {
    return discountedPrice ? extendedPrice - discountedPrice : extendedPrice
  }

  const handleRefundType = () => {
    switch (activity?.refund_type) {
      case 'refund':
        return 'rechazo total'
      case 'partial_refund':
        return 'rechazo parcial'
      default:
        return 'devolución'
    }
  }

  const isDelivery = () => {
    return activity?.refund_type && activity?.refund_type !== 'salesman_refund'
  }

  const tableRows = [
    {
      label: 'Fecha de documento:',
      value: getFormattedDate(activity.created_at)
    },
    {
      label: isDelivery() ? 'Número de rechazo:' : 'Número de devolución:',
      value: activity.refund_db_ref || '-'
    },
    {
      label: 'Fecha de ejecución:',
      value: activity.created_date
        ? getFormattedDate(activity.created_date)
        : activity.collected_at
          ? getFormattedDate(activity.collected_at)
          : '-'
    },
    {
      label: 'Vendedor:',
      value: activity.salesman_name || '-'
    },
    isDelivery() && {
      label: 'Tipo de cliente:',
      value: activity.client_payment_type
        ? capitalizeFirstLetterFrom(activity.client_payment_type)
        : '-'
    },
    handleRefundType() !== 'rechazo parcial' && {
      label: `Motivo de ${isDelivery() ? 'rechazo' : 'devolución'}:`,
      value: activity.motive || '-'
    },
    {
      label: 'Número de factura:',
      value: activity.invoice_number || '-'
    },
    !isDelivery() && {
      label: 'Comentarios generales:',
      value: activity.comments || '-'
    },
    {
      label: 'Dentro de geocerca:',
      value:
        effectiveLocationWithAllowedLocation(
          activity.effective_location,
          activity.has_allowed_location
        ) || '-'
    }
  ].filter(Boolean)

  return (
    <div className="container-fluid">
      <div className="row">
        {transactionUuid && (
          <>
            <div className="row show-product-header">
              <div className="col-sm-6">
                <h3 className="margin-text-subtitle">Detalles de {handleRefundType()}:</h3>
              </div>
            </div>
            <hr />
          </>
        )}

        <div>
          <div className="show-product-header-ubiqua-app show-header">
            <div>
              <div className="order-client-name">{activity.client_name}</div>
              <div className="order-client-db_ref">Código: {activity.client_db_ref}</div>
            </div>
          </div>
          <hr />

          <table cellSpacing="10">
            <tbody>
              {tableRows.map(({ label, value }, idx) => (
                <tr key={idx}>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>{label}</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{value}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />

          <div className="mr-85 mb-10">
            <b>{isDelivery() ? 'Productos' : 'Productos en devolución'}:</b>
          </div>

          <Table hover responsive>
            <thead className="table-header-gray">
              <tr>
                <th style={{ verticalAlign: 'middle' }}>CÓDIGO</th>
                <th style={{ verticalAlign: 'middle' }}>NOMBRE DEL PRODUCTO</th>
                {!isDelivery() && <th style={{ verticalAlign: 'middle' }}>N° LOTE</th>}
                {handleRefundType() !== 'rechazo total' && (
                  <th style={{ verticalAlign: 'middle' }}>
                    MOTIVO {isDelivery() ? 'DE RECHAZO' : ''}
                  </th>
                )}
                {!isDelivery() && <th style={{ verticalAlign: 'middle' }}>PESO NETO</th>}
                <th style={{ verticalAlign: 'middle' }}>UNIDAD DE VENTA</th>
                <th style={{ verticalAlign: 'middle' }}>CANTIDAD</th>
                <th style={{ verticalAlign: 'middle' }}>PRECIO</th>
                <th style={{ verticalAlign: 'middle' }}>SUB-TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {activity.details?.map((product, index) => (
                <tr key={index}>
                  <td>{product.product_db_ref}</td>
                  <td>{product.product_name}</td>
                  {!isDelivery() && <td>{product.batch_number || 'N/A'}</td>}
                  {handleRefundType() !== 'rechazo total' && <td>{product.motive || 'N/A'}</td>}
                  {!isDelivery() && <td>{getWeight(product.weight)}</td>}
                  <td>{product.price_unit}</td>
                  <td>
                    {isDelivery()
                      ? parseInt(product.quantity)
                      : Number(product.quantity).toFixed(2)}
                  </td>
                  <td>{formatMoney(Number(product.unit_price))}</td>
                  <td>
                    {formatMoney(getRowSubtotal(product.extended_price, product.discount_price))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="totals-container">
            <div className="totals-card">
              <div className="totals-line">
                <div>
                  <b>SUB-TOTAL:</b>
                </div>
                <div>{formatMoney(activity.subtotal)}</div>
              </div>
              {taxSettings.tax_rate_2 && (
                <div className="totals-line">
                  <div>
                    <b>{taxSettings.tax_rate_2}:</b>
                  </div>
                  <div>{formatMoney(0)}</div>
                </div>
              )}
              <div className="totals-line">
                <div>
                  <b>{taxSettings.tax_rate}:</b>
                </div>
                <div>{formatMoney(activity.taxes)}</div>
              </div>
              <div className="totals-line">
                <div>
                  <h4>
                    <b>TOTAL:</b>
                  </h4>
                </div>
                <div>{formatMoney(activity.total)}</div>
              </div>
              {!isDelivery() && (
                <>
                  <hr />
                  <div className="totals-line">
                    <div>
                      <b>PESO TOTAL:</b>
                    </div>
                    <div>{getWeight(activity.total_weight)}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RefundDetailsTab
