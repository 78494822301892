import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import fetchWithAuth from '../helpers/fetchWithAuth'
import Pagination from '../layout/Pagination'
import { formatMoney } from '../helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faMagnifyingGlass, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilterList'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import DatePicker from 'react-multi-date-picker'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import { STATUS_OPTIONS, DATE_PICKER_SETTINGS } from './constants/constants'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const DeliveryIndexView = () => {
  const initPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let page = searchParams.get('page')
    let currentPage = 1
    if (page) {
      currentPage = Number(page)
    } else {
      searchParams.set('page', '1')
      let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      window.history.pushState(null, '', newRelativePathQuery)
    }
    return {
      per_page: 25,
      current_page: currentPage,
      total_pages: 1,
      searchInput: ''
    }
  }

  const [data, setData] = useState(null)
  const [title, setTitle] = useState('Entregas')
  const [collapsed, setCollapsed] = useState(false)
  const [clientsUid, setClientsUid] = useState([])
  const [status, setStatus] = useState(STATUS_OPTIONS)

  const [filters, setFilters] = useState([])
  const [fetchDataStatus, setFetchDataStatus] = useState({ filters: false })
  const [allSelectedOptions, setAllSelectedOptions] = useState(new Map())
  const [filterQuery, setFilterQuery] = useState('')
  const [dateQuery, setDateQuery] = useState(null)
  const [selectedDates, setSelectedDates] = useState(null)
  const [pagination, setPagination] = useState(initPaginationData())
  const [labelToIdMapping, setLabelToIdMapping] = useState({})
  const [filterVersion, setFilterVersion] = useState(0)
  const [searchInput, setSearchInput] = useState('')
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [filterSelectionMade, setFilterSelectionMade] = useState(false)
  const [resetTriggeredByDeselection, setResetTriggeredByDeselection] = useState(false)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const updateAllSelectedOptions = (identifier, selectedLabels) => {
    setAllSelectedOptions((prevOptions) => {
      const updatedOptions = new Map(prevOptions)
      const labels = Array.isArray(selectedLabels)
        ? selectedLabels
        : Array.from(selectedLabels || [])

      const selectedIds = labels.map((label) => labelToIdMapping[label])
      updatedOptions.set(identifier, selectedIds)
      return updatedOptions
    })
    buildFiltersQuery()
  }

  const filterMappings = {
    'NOMBRE DE ENTREGADOR': 'salesman_uid',
    'NOMBRE DE CLIENTE': 'client_uid',
    Estado: 'status'
  }


  const buildFiltersQuery = () => {
    let queryParam = '';
  
    allSelectedOptions.forEach((value, key) => {
      if (value.length !== 0) {
        const paramKey = filterMappings[key];
        if (paramKey) {
          let selectedValues = [...value];
  
          //Esto por que el Parcial tiene 2 estatus y tambien debe enviarse al seleccionar todo

          if (selectedValues.length === 1 && selectedValues[0] === 'partial') {
            selectedValues = ['partial_delivery_receipt', 'partial_refund'];
          }
            if (selectedValues.includes('partial')) {
            selectedValues = selectedValues.filter(val => val !== 'partial');
            selectedValues.push('partial_delivery_receipt', 'partial_refund');
          }
  
          queryParam += `&${paramKey}=${selectedValues.join(',')}`;
        }
      }
    });
  
    pagination.current_page = 1;
    setFilterQuery(queryParam);
  };

  const prepareFilterData = () => {
    const localLabelToIdMapping = {}
    if (!clientsUid) {
      return
    }
    const clientOptions = clientsUid
      .filter((client) => (client.client_db_ref || '').trim() !== '')
      .map((client) => {
        const displayText = `${client.client_description} - ${client.client_db_ref}`
        localLabelToIdMapping[displayText] = client.client_db_ref
        return displayText
      })

    const salesmanOptions = Array.from(
      new Set(
        clientsUid
          .filter((client) => (client.client_db_ref || '').trim() !== '' && client.salesman_name)
          .map((client) => {
            const displayText = `${client.salesman_db_ref} - ${client.salesman_name}`
            localLabelToIdMapping[displayText] = client.salesman_db_ref
            return displayText
          })
      )
    )

    const statusOptions = status.map((option) => {
      localLabelToIdMapping[option.name] = option.value
      return option.name
    })

    const newFilters = [
      { key: 'NOMBRE DE ENTREGADOR', value: salesmanOptions },
      { key: 'NOMBRE DE CLIENTE', value: clientOptions },
      { key: 'Estado', value: statusOptions }
    ]

    setFilters(newFilters)
    setLabelToIdMapping(localLabelToIdMapping)
    setFetchDataStatus({ filters: true })
  }

  const handleDateFilter = (datesObj) => {
    let formatedDates = []
    let queryParam = ''
    let startDate = new Date()
    let endDate = new Date()

    if (!datesObj || datesObj.length === 0) {
      const otherFiltersSelected = Array.from(allSelectedOptions.values()).some(
        (value) => value.length > 0
      )
      setDateQuery(null)
      setSelectedDates(null)
      setFilterSelectionMade(otherFiltersSelected)
      setResetTriggeredByDeselection(!otherFiltersSelected)
    } else {
      datesObj.map((date) => {
        formatedDates.push(new Date(date.unix * 1000))
      })
      setSelectedDates(datesObj)
      if (formatedDates.length > 1) {
        startDate = formatedDates[0] < formatedDates[1] ? formatedDates[0] : formatedDates[1]
        endDate = formatedDates[0] < formatedDates[1] ? formatedDates[1] : formatedDates[0]
      } else if (formatedDates.length === 1) {
        startDate = formatedDates[0]
        endDate = formatedDates[0]
      }
      
      const startDateISO = (startDate).toISOString()
      const endDateISO = (endDate).toISOString()
      queryParam = `&start_date=${startDateISO}&end_date=${endDateISO}`
      setDateQuery(queryParam)
      setFilterSelectionMade(true)
      setResetTriggeredByDeselection(false)
    }
    pagination.current_page = 1
  }

  const resetReactFilters = () => {
    setAllSelectedOptions(new Map())
    setFilterQuery('')
    setDateQuery(null)
    setSelectedDates(null)
    setSearchInput('')
    setFilterVersion((prevVersion) => prevVersion + 1)
    setTriggerSearch((prev) => !prev)
  }

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  useEffect(() => {
    prepareFilterData()
  }, [clientsUid])

  useEffect(() => {
    initPaginationData()
    async function fetchAll() {
      await fetchClientsFromServer()
    }
    fetchAll()
  }, [])

  useEffect(() => {
    if (allSelectedOptions.size > 0) {
      buildFiltersQuery()
    }
  }, [allSelectedOptions])

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    let targetId = target.id
    switch (targetId) {
      case 'first-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: 1 })
        }
        break
      case 'last-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.total_pages })
        }
        break
      case 'previous-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: pagination.current_page - 1 })
        }
        break
      case 'next-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.current_page + 1 })
        }
        break
    }
  }

  const isAnyFilterSelected = () => {
    const filtersSelected = Array.from(allSelectedOptions.entries()).some(
      ([key, value]) => value.length > 0
    )
    const dateSelected = dateQuery !== null && dateQuery.trim() !== ''

    return filtersSelected || dateSelected
  }

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', pagination.current_page)
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const fetchClientsFromServer = async (loadingState = true) => {
    if (loadingState) {
      setFetchDataStatus({ ...fetchDataStatus, filters: true })
    }
    try {
      const response = await fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/delivery_requests?client_names_only=true`,
        {
          method: 'GET',
          signal: abortController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
      if (response.status === 401) {
        console.error('Authorization error', response)
        return
      }

      const data = await response.json()
      setClientsUid(data['result'])
    } catch (error) {
      console.error('Fetch error:', error)
    } finally {
      setFetchDataStatus({ ...fetchDataStatus, filters: false })
    }
  }

  const fetchDataFromServer = async () => {
    const per_page = pagination.per_page
    const page = pagination.current_page

    const response = await fetchWithAuth(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/delivery_requests?page=${page}&per_page=${per_page}${filterQuery || dateQuery || searchInput ? '&filter_search=true' : ''}${filterQuery ? filterQuery : ''}${dateQuery ? dateQuery : ''}${searchInput ? `&q=${encodeURIComponent(searchInput)}` : ''}`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = response
    setPagination({ ...pagination, total_pages: Math.ceil(data['count'] / pagination.per_page) })
    setData(data['table'])
  }

  useEffect(() => {
    updateURLPaginationData()
    async function fetchAll() {
      await fetchDataFromServer()
    }
    fetchAll()
  }, [pagination.current_page, filterQuery, dateQuery, triggerSearch])

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      setTriggerSearch((prev) => !prev)
      pagination.current_page = 1
    }
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    pagination.current_page = 1
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      pagination.current_page = 1
      fetchDataFromServer()
    }
  }

  const showModel = (event, model) => {
    event.preventDefault()
    const id = model.uuid || model.uid
    const status = model.status

    if (model.status === 'partial_refund' || model.status === 'refund') {
      window.open(`/devoluciones/${id}`, '_blank')
    } else if (model.status === 'delivery_receipt' || model.status === 'partial_delivery_receipt') {
      window.open(`/entregas/${id}`, '_blank')
    } else {
      window.open(`/entregas/${encodeURIComponent(id)}?status=${status}`, '_blank')
    }
  }

  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  const filtersArray = Array.isArray(filters) ? filters : Array.from(filters || [])
  const anyFilterEmpty = filtersArray.some((filter) => filter.value.length === 0)

  return (
    <div>
      <div>
        <div>
          <TopBar
            searchPlaceholder={''}
            userName={''}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
            <div className="index-header detailed-container">
              <h2 className="mb-20">{title}</h2>
            </div>
            <div className="index-table">
              <div className="show-area">
                <h4 className="text-sub-title">Filtrar entregas por:</h4>
                <div className="filters-search-container mb-10">
                  <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
                  <div className="filters-container">
                    {filters.map((filter, indx) => (
                      <div key={`flr-${indx}`} className="mr-10">
                        <ReactFilter
                          className="mr-10"
                          title={filter.key}
                          identifier={filter.key}
                          options={filter.value}
                          resetWatcher={filterVersion}
                          onSelectionChange={updateAllSelectedOptions}
                          disabled={anyFilterEmpty}
                        />
                      </div>
                    ))}
                    <DatePicker
                      disabled={anyFilterEmpty}
                      range
                      rangeHover
                      dateSeparator=" - "
                      months={DATE_PICKER_SETTINGS.months}
                      weekDays={DATE_PICKER_SETTINGS.weekDays}
                      format="DD MMM"
                      weekStartDayIndex={DATE_PICKER_SETTINGS.weekStartDayIndex}
                      highlightToday={false}
                      numberOfMonths={2}
                      maxDate={moment().local().endOf('day').toDate()}
                      onChange={(dateObjects) => handleDateFilter(dateObjects)}
                      value={selectedDates}
                      plugins={[
                        <Toolbar
                          position="bottom"
                          names={{
                            deselect: 'Deseleccionar'
                          }}
                          sort={['deselect']}
                        />
                      ]}
                      render={(value, openCalendar) => {
                        return (
                          <div
                            key={`flr-date`}
                            className={`mr-10 ${anyFilterEmpty ? 'react-filter-disabled' : ''}`}
                          >
                            <div className="react-filter-box filter-input-width-datepicker">
                              <div
                                title="Rango de fechas"
                                onClick={!anyFilterEmpty ? openCalendar : undefined}
                                className="react-filter-box-clickable-zone"
                              >
                                <div className="react-filter-titles">
                                  {value?.length > 0 && (
                                    <>
                                      {Array.isArray(value) ? (
                                        <div className="filter-title-overflow">
                                          {value.join(' - ')}
                                        </div>
                                      ) : (
                                        <div className="filter-title-overflow">{value}</div>
                                      )}
                                      <FontAwesomeIcon
                                        style={{ color: 'ef823a' }}
                                        icon={faCalendar}
                                      />
                                    </>
                                  )}
                                  {value?.length == 0 && (
                                    <>
                                      <div className="filter-title-overflow">RANGO DE FECHAS</div>
                                      <FontAwesomeIcon
                                        style={{ color: anyFilterEmpty ? '#D5D5D5' : '#597fa9' }}
                                        icon={faCalendar}
                                      />{' '}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    />
                  </div>
                  <div className="search-input-containter">
                    <input
                      value={searchInput}
                      onChange={handleSearch}
                      onKeyDown={handleEnterSearch}
                      className="round-search-input"
                      style={{ fontSize: 12 }}
                      placeholder="Buscar por # de documento o nombre de cliente"
                    />
                    {searchInput === '' && (
                      <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
                    )}
                    {searchInput && (
                      <div
                        title="Limpiar texto"
                        onClick={resetReactFilters}
                        className="round-search-input-x"
                      >
                        X
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-20">
                  {isAnyFilterSelected() && (
                    <a onClick={resetReactFilters} className="reset-filter cursor-pointer mb-20">
                      Restablecer filtros aplicados
                    </a>
                  )}
                </div>
                <Table hover responsive>
                  <thead className="table-header-gray">
                    <tr>
                      <th style={{ verticalAlign: 'middle', padding: '10px 25px', width: '15%' }}>
                        FECHA DE DOCUMENTO
                      </th>
                      <th style={{ verticalAlign: 'middle', width: '20%' }}>ENTREGADOR</th>
                      <th style={{ verticalAlign: 'middle', width: '10%' }}>CÓDIGO DE CLIENTE</th>
                      <th style={{ verticalAlign: 'middle', width: '17%' }}>NOMBRE DE CLIENTE</th>
                      <th style={{ verticalAlign: 'middle', width: '10%' }}># DE DOCUMENTO</th>
                      <th style={{ verticalAlign: 'middle', width: '10%' }}>TIPO DE DOCUMENTO</th>
                      <th style={{ verticalAlign: 'middle', width: '10%' }}>TOTAL</th>
                      <th style={{ verticalAlign: 'middle' }}>ESTADO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length > 0 ? (
                      data.map((model, index) => (
                        <tr className="cursor-pointer" key={index}>
                          <td
                            className="td-text-limited td-text-limited-space"
                            id={model.uuid || model.uid}
                            onClick={(event) => showModel(event, model)}
                          >
                            {getFormattedDate(model.created_date)}
                          </td>
                          <td
                            className="td-text-limited"
                            id={model.uuid || model.uid}
                            onClick={(event) => showModel(event, model)}
                          >
                            {model.salesman_db_ref} - {model.salesman_name}
                          </td>
                          <td
                            className="td-text-limited"
                            id={model.uuid || model.uid}
                            onClick={(event) => showModel(event, model)}
                          >
                            {model.client_db_ref}
                          </td>
                          <td
                            className="td-text-limited"
                            id={model.uuid || model.uid}
                            onClick={(event) => showModel(event, model)}
                          >
                            {model.client_name}
                          </td>
                          <td
                            className="td-text-limited"
                            id={model.uuid || model.uid}
                            onClick={(event) => showModel(event, model)}
                          >
                            {model.uid}
                          </td>
                          <td
                            className="td-text-limited"
                            id={model.uuid || model.uid}
                            onClick={(event) => showModel(event, model)}
                          >
                            {model.delivery_type_text || model.delivery_type}
                          </td>
                          <td
                            className="td-text-limited"
                            id={model.uuid || model.uid}
                            onClick={(event) => showModel(event, model)}
                          >
                            {formatMoney(Number(model.total))}
                          </td>
                          <td
                            id={model.uuid || model.uid}
                            onClick={(event) => showModel(event, model)}
                          >
                            <div className="td-delivery-status">
                              {model.status === 'open' && (
                                <div className="delivery-status delivery-open-style">
                                  POR ENTREGAR
                                </div>
                              )}
                              {model.status === 'delivery_receipt' && (
                                <div className="delivery-status delivery-received-style">
                                  ENTREGADO
                                </div>
                              )}
                              {(model.status === 'partial_delivery_receipt' ||
                                model.status === 'partial_refund') && (
                                <div className="delivery-status delivery-received-style">
                                  PARCIAL
                                </div>
                              )}
                              {model.status === 'refund' && (
                                <div className="delivery-status delivery-received-style">
                                  RECHAZADO
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="td-text-limited td-text-limited-space">
                          No hay resultados
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <nav className="index-table">
              {pagination.total_pages > 1 && (
                <div onClick={handlePaginationClick}>
                  <Pagination
                    current_page={pagination.current_page}
                    total_pages={pagination.total_pages}
                  />
                </div>
              )}
            </nav>
          </div>
        </div>
      </div>
      <div>
        <NavBar activeItem="Entregas" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}
export default DeliveryIndexView
